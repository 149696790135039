import Vue from 'vue'

import {
  Button,
  Toast,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Lazyload,
  Icon,
  List,
  Form,
  Field,
  Uploader,
  Tab,
  Tabs,
  ActionSheet,
  Picker,
  Popup,
  Col,
  Row,
  Dialog,
} from 'vant'
import 'vant/lib/index.css'

Toast.setDefaultOptions({
  duration: 1500
})

Vue
.use(Dialog)
.use(Col)
.use(Row)
.use(Popup)
.use(Picker)
.use(ActionSheet)
.use(Tabs)
.use(Tab)
.use(Button)
.use(Toast)
.use(Swipe)
.use(SwipeItem)
.use(Tabbar)
.use(TabbarItem)
.use(Lazyload)
.use(Icon)
.use(List)
.use(Form)
.use(Field)
.use(Uploader)
