import {
  getQueryString
} from '@/utils/helper'

import { getUserInfo } from '@/api'

const user = {
    state: {
        token: window.localStorage.getItem('token'), //用户token
        userInfo:{
            name: '',
            avatar: '',
            phone: '',
            is_bind: 0,
            nickname:'',
            area_name:'',
            is_rd:0,
        }
    },
  mutations: {
    // 保存用户token
    SET_TOKEN: function (state, token) {
        state.token = token
        window.localStorage.setItem('token',token)
    },
    setUserInfo:function(state, payload){
        let info = payload;
        console.log(info)
        state.userInfo.name = info && info.nickname
        state.userInfo.nickname = info && info.nickname
        state.userInfo.avatar = payload.avatar
        state.userInfo.phone = info && info.mobile
        state.userInfo.is_bind = info && info.is_bind_mobile
        state.userInfo.is_rd = info && info.is_rd
        state.userInfo.area_name = info && info&&info.area_name//地区名称
    },
    clearUserInfo:function(state){
        state.token = ""
    }

  },
  actions: {
    // 获取用户token
    getToken(state, payload) {
        // console.log(payload)
        return new Promise(resolve => {
            if (payload.access_token) {
                state.commit('SET_TOKEN', payload.access_token)
                resolve()
                return
            }
            let token = getQueryString('access_token')
            if (!token) {
                // console.log('授权页面')
                let url = encodeURIComponent(window.location.href.split('?')[0])
                window.location.href = process.env.VUE_APP_BASE_URL + "/home/auth/wxlogin?appid=1&redirect_uri=" + url
            } else {
                state.commit('SET_TOKEN', token)
                resolve()
            }
        })
    },
    // 获取用户信息
    getUserInfo(context){
        return new Promise(resolve=>{
            getUserInfo().then((res)=>{
                const userInfo = res.data
                context.commit('setUserInfo',userInfo)
                resolve()
            })
        })
    },
  },
}
export default user