import axios from 'axios'

let config = {
    // baseURL: process.env.VUE_APP_BASE_URL,//后做注 不做不注释
    timeout: 10000, // Timeout
}

const _axios = axios.create(config)

//request拦截器
_axios.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error)
    }
)

//respone拦截器
_axios.interceptors.response.use(
    response => {
        if(response.data.code == 401){
            window.sessionStorage.removeItem('token')
            let url = encodeURIComponent(window.location.href.split('?')[0])
            window.location.href = process.env.VUE_APP_BASE_URL + "/home/auth/wxlogin?appid=1&redirect_uri=" + url
        }
        return response
    },
    error => { //默认除了2XX之外的都是错误的，就会走这里
        let status = error.response.status;
        if (error && status) {
            let errMsg = {
                400: "请求出错",
                401: "请登录后再操作",
                403: "拒绝访问",
                408: "请求超时",
                500: "服务器内部错误",
                501: "服务未实现",
                502: "网关错误",
                503: "服务不可用",
                504: "网关超时",
                505: "http版本不受支持"
            };
            console.log(errMsg);
            // console.log("err:" + );
            // Message.error(errMsg[status]);
        }
        return Promise.reject(error);
    }
)

export default _axios;