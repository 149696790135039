import HTTP from '../utils/http'
import axios from "../utils/axios";
// 上传图片
export const upload = (params) => HTTP("/api/common/upload", params, false, 'post')

// 个人信息
export const getUserInfo = () => HTTP("/api/user/getProfile")
export const record = (params) => HTTP("/api/v2/commons/postView",params,false,)
// export function getUserInfo() {
//     return axios({
//       url: "api/user/userInfo",
//       method: "get",
//       para
//       },
//     });
//   }
  