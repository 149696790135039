import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import routesList from '../utils/requireRouter'
import { record } from '@/api'
import { Toast } from 'vant'
Vue.use(VueRouter)

const routes = routesList

const router = new VueRouter({
    routes,
    // mode: 'history',
})

// // 验签
function verificateIsCheck(to, next) {
    if (store.getters.userInfo.avatar) {
        console.log('因为没有授权，从这里走的')
        let is_bind = store.state.user.userInfo.is_bind
        let arr = [
            'Suggest', 'proposalDetail', 'votingDetail'
        ]
        if (arr.includes(to.name)) {
            if (is_bind == 0) {
                Toast.fail('请先实名')
                next({ path: '/attestation' })
            }else{
                next();
            }
        }
        else {
            next();
            console.log('走到了最后')
        }
    } else {
        store.dispatch("getUserInfo").then(function () {
            next();
        });
    }
}

function verificateInfo(to, next) {
    if (store.getters.userInfo.avatar) {
        verificateIsCheck(to, next)
        // next()
    } else {
        store.dispatch('getUserInfo')
            .then(function () {
                verificateIsCheck(to, next)
                // next()
            })
    }
    // next()
}

function recordNum(tpe) {
    let params = {
        device: "WX",
        tpe: tpe
    }
    record(params).then(res => {
        // console.log(res)
    })
}
//路由守卫
router.beforeEach((to, from, next) => {

    if (to.meta.title) {
        document.title = to.meta.title
        store.dispatch("getUserInfo")
    }


    if (!store.getters.token) {
        store.dispatch('getToken', {
            token: to.query.token
        })
            .then(function () {
                verificateInfo(to, next)
            })
    } else {
        verificateInfo(to, next)
    }
})
router.afterEach((to, from) => {


})

export default router
