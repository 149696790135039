export const home = {
    path: '/',
    name: 'home',
    component: () => import('./'),
    meta: {
        title: '娄庄人大信息平台',
        keepAlive: false, 
      }
}

// 综治动态
export const dynamic = {
    path: '/dynamic/:cid/:name',
    name: 'dynamic',
    component: () => import('./dynamic/dynamic.vue'),
    meta: {
        title: '资讯列表'
    }
}
// 文章详情
export const articleDetail = {
    path: '/articleDetail/:id',
    name: 'articleDetail',
    component: () => import('./articleDetail/articleDetail.vue'),
    meta: {
        title: '文章详情'
    }
}
// 便民服务
export const servePerson = {
    path: '/servePerson',
    name: 'servePerson',
    component: () => import('./servePerson/servePerson.vue'),
    meta: {
        title: '便民服务'
    }
}
// 人大概况
export const survey = {
    path: '/survey',
    name: 'survey',
    component: () => import('./survey/survey.vue'),
    meta: {
        title: '人大概况'
    }
}

// 组织架构
export const organization = {
    path: '/organization',
    name: 'organization',
    component: () => import('./organization/organization.vue'),
    meta: {
        title: '组织架构'
    }
}
// 人大简介
export const articleDetail1 = {
    path: '/articleDetail1/:id',
    name: 'articleDetail1',
    component: () => import('./articleDetail1/articleDetail1.vue'),
    meta: {
        title: '人大简介'
    }
}
// 组织架构详情
export const organizationImages = {
    path: '/organizationImages/:id',
    name: 'organizationImages',
    component: () => import('./organizationImages/organizationImages.vue'),
    meta: {
        title: '组织架构'
    }
}
//工作状态
export const partyLeads = {
    path: '/partyLeads',
    name: 'partyLeads',
    component: () => import('./partyLeads/partyLeads.vue'),
    meta: {
        title: '工作状态',
        keepAlive: true, // 需要被缓存

    }
}
// 主题活动
export const topic = {
    path: '/topic/:id',
    name: 'topic',
    component: () => import('./partyLeads/topic.vue'),
    meta: {
        title: '主题活动'
    }
}
//依法履职
export const accordingLaw = {
    path: '/accordingLaw',
    name: 'accordingLaw',
    component: () => import('./accordingLaw/accordingLaw.vue'),
    meta: {
        title: '依法履职',
        keepAlive: true, 
    }
}
export const accordingList = {
    path: '/accordingList',
    name: 'accordingList',
    component: () => import('./accordingLaw/accordingList.vue'),
    meta: {
        title: '镇人代会'
    }
}
export const accordingDetail = {
    path: '/accordingDetail/:id',
    name: 'accordingDetail',
    component: () => import('./accordingLaw/accordingDetail.vue'),
    meta: {
        title: '镇人代会'
    }
}


//民意直通车
export const MySuggestList = {
    path: '/MySuggestList',
    name: 'MySuggestList',
    component: () => import('./MySuggestList/MySuggestList.vue'),
    meta: {
        title: '民意直通车'
    }
}
// 投票评议
export const voting = {
    path: '/voting',
    name: 'voting',
    component: () => import('./voting/voting.vue'),
    meta: {
        title: '投票评议'
    }
}

export const votingDetail = {
    path: '/votingDetail/:id',
    name: 'votingDetail',
    component: () => import('./voting/votingDetail.vue'),
    meta: {
        title: '投票评议'
    }
}

export const voteInfo = {
    path: '/voteInfo/:id',
    name: 'voteInfo',
    component: () => import('./voting/voteInfo.vue'),
    meta: {
        title: '投票评议'
    }
}
// 意见征集
export const proposal = {
    path: '/proposal',
    name: 'proposal',
    component: () => import('./proposal/proposal.vue'),
    meta: {
        title: '意见征集'
    }
}
export const proposalDetail = {
    path: '/proposalDetail/:id',
    name: 'proposalDetail',
    component: () => import('./proposal/proposalDetail.vue'),
    meta: {
        title: '意见征集'
    }
}
export const questionnaireList = {
    path: '/questionnaireList',
    name: 'questionnaireList',
    component: () => import('./questionnaire/questionnaireList.vue'),
    meta: {
        title: '问卷调查'
    }
}
export const questionnaireDetail = {
    path: '/questionnaireDetail/:id',
    name: 'questionnaireDetail',
    component: () => import('./questionnaire/questionnaireDetail.vue'),
    meta: {
        title: '问卷调查'
    }
}

// 民意直通车
export const Suggest = {
    path: '/Suggest',
    name: 'Suggest',
    component: () => import('./Suggest/Suggest.vue'),
    meta: {
        title: '民意直通车'
    }
}