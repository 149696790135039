import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './styles/common.less'
import './styles/global.less'
import './assets/iconfont/iconfont.css'
// import './assets/iconfont/iconfont.ttf' 
import Vant from 'vant'
import './utils/vant'

Vue.config.productionTip = false
Vue.prototype.$IMG_PATH = process.env.VUE_APP_IMG_PATH

Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
