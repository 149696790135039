export const Mine = {
    path: '/Mine',
    name: 'Mine',
    component: () => import('./Mine.vue'),
    meta: {
        title: '个人中心'
    }
}
// 实名认证
export const attestation = {
    path: '/attestation',
    name: 'attestation',
    component: () => import('./attestation/attestation.vue'),
    meta: {
        title: '实名认证'
      }
}


// 投票评议
export const myVote = {
    path: '/myVote',
    name: 'myVote',
    component: () => import('./myVote/myVote.vue'),
    meta: {
        title: '投票评议'
    }
}

// 投票评议详情
export const myVoteList = {
    path: '/myVoteList',
    name: 'myVoteList',
    component: () => import('./myVote/myVoteList.vue'),
    meta: {
        title: '投票评议'
    }
}

// 意见征集
export const myproposal = {
    path: '/myproposal',
    name: 'myproposal',
    component: () => import('./myproposal/myproposal.vue'),
    meta: {
        title: '意见征集'
    }
}
export const myProposalDetail = {
    path: '/myProposalDetail/:id',
    name: 'myProposalDetail',
    component: () => import('./myproposal/myProposalDetail.vue'),
    meta: {
        title: '意见征集'
    }
}

// 民意直通车
export const SuggestDetaill = {
    path: '/SuggestDetaill/:id',
    name: 'SuggestDetaill',
    component: () => import('./SuggestDetaill/SuggestDetaill.vue'),
    meta: {
        title: '民意直通车'
    }
}

// 民意直通车列表
export const mySuggest = {
    path: '/mySuggest',
    name: 'mySuggest',
    component: () => import('./mySuggest/mySuggest.vue'),
    meta: {
        title: '民意直通车'
    }
}
// 选民意见
export const electorateList = {
    path: '/electorateList',
    name: 'electorateList',
    component: () => import('./electorate/electorateList.vue'),
    meta: {
        title: '选民意见'
    }
}
// 选民意见
export const electorateDetail = {
    path: '/electorateDetail/:id',
    name: 'electorateDetail',
    component: () => import('./electorate/electorateDetail.vue'),
    meta: {
        title: '选民意见处理'
    }
}
export const myQuestionnaireList = {
    path: '/myQuestionnaireList',
    name: 'myQuestionnaireList',
    component: () => import('./myQuestionnaire/myQuestionnaireList.vue'),
    meta: {
        title: '问卷调查'
    }
}
export const myQuestionnaireDetail = {
    path: '/myQuestionnaireDetail/:id',
    name: 'myQuestionnaireDetail',
    component: () => import('./myQuestionnaire/myQuestionnaireDetail.vue'),
    meta: {
        title: '问卷调查'
    }
}
