const routesList = []

function importAll(r) {
  r.keys().forEach( (key) => {
    Object.keys(r(key)).forEach((item) => {
        routesList.push(r(key)[item])
    })
  })
}

importAll(require.context('../views', true, /^\.\/(\w*\/)+(\w*)\.router\.js$/))

export default routesList