// 获取查询字符串
export const getQueryString = (data) =>  {
    let reg = new RegExp('(^|&)' + data + '=([^&]*)(&|$)', 'i')
    let r = window.location.search.substr(1).match(reg)
    if (r != null) {
        let url = unescape(r[2])
        let tok = url.indexOf('/')
        if(tok != -1){
            return url.substring(0,tok)
        }else {
            return url
        }
    }
    return null
}