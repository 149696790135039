import axios from 'axios'
import { Toast } from 'vant'
import router from '../router'
// axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true

//request拦截器
axios.interceptors.request.use(
    config => {
        if (localStorage.token) {
            config.headers.token = localStorage.token
        }
        config.headers.appid = 1
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
//respone拦截器
axios.interceptors.response.use(
    response => {
        // console.log('response',response)
        if (response.data.code == 401) {
            window.localStorage.removeItem('token')
            let url = encodeURIComponent(window.location.href.split('?')[0])
            window.location.href = process.env.VUE_APP_BASE_URL + "/home/auth/wxlogin?appid=1&redirect_uri=" + url
        }
        return response

    },
    error => { //默认除了2XX之外的都是错误的，就会走这里
        if (error.response) {
        }
        return Promise.reject(error.response);
    }
)

function HTTP(url, param = {}, isCarryToken = false, type = "get", allowed = false) {
    return new Promise(function (resolve, reject) {
        let params = {}
        if (type == 'post') {
            isCarryToken ? param.token = window.localStorage.getItem('token') : param
            params = param
        } else {
            isCarryToken ? param.token = window.localStorage.getItem('token') : param
            params.params = param
        }
        axios[type](url, params)
            .then((res) => {
                if (res.data.code == 0) {
                    resolve(res.data)
                } 
                else {
                    if (allowed) {
                        resolve()
                    } else {
                        // console.log(res,11111)
                        if (res.data.code == 10002) {
                            resolve(res.data)
                            Toast.fail(res.data.msg || res.data.message)
                            setTimeout(() => {
                                router.replace('/attestation')
                            }, 1200);

                        } else {
                            Toast.fail(res.data.msg || res.data.message)
                        }

                    }
                }
            }).catch((err) => {
                Toast.fail("请求出错")
            })
    })
}

export default HTTP